.pelecard-page__order {
  padding: 30px;
  min-height: 200px;
}

.pelecard-page__title {
  font-weight: 600;
  text-align: center;
}

.pelecard-page__info {
  text-align: center;
  margin: 20px 30px;
}

.outlineBlueBtn {
  background-color: white !important;
  border: 4px solid var(--primary) !important;
  color: var(--primary) !important;
}
