.productDetails-title {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  margin: 2% 0;
}

.MuiDialog-container .MuiPaper-rounded {
  /* min-width: 94% !important; */
}

.productDetails-contentTitle {
  font-size: 18px;
  color: #000;
  font-weight: 600;
  margin: 2% 0;
}

.search-product {
  margin: 2% 0;
}

.search-product > * {
  width: 100%;
  display: flex;
  justify-content: center;
}

.filter {
  flex-direction: row !important;
  width: 100%;
  justify-content: space-evenly;
}

.filter .filterBlock {
  display: flex;
  border: none;
  /* width: 400px; */
  align-items: center;
  justify-content: space-evenly;
}

.filter .filterBlock .priceSlider {
  width: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.filter .searchWrapper {
  /* width: 200px; */
  display: flex;
  align-items: center;
}

.filter .searchWrapper .search-container {
  width: 100%;
}

.product-stores {
  width: 100%;
}

.productDetails-inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 300px;
  position: relative;
}

.store-box {
  box-shadow: 0px 1px 8px 0px rgb(0 0 0 / 12%);
  border-radius: 15px;
  padding-bottom: 2%;
  min-height: 235px;
  min-width: 265px;
  position: relative;
}

.store-box__img {
  width: 100%;
  /* min-width: 100px; */
  height: 140px;
  border-radius: 15px 15px 0 0;
  object-fit: cover;
  /* margin: 10px; */
  z-index: -10;
}

.store-box__logo {
  position: absolute;
  width: 90px;
  height: 90px;
  top: 40px;
  right: 5px;
  border-radius: 50%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: white;
}

.store-block {
  margin-bottom: 50px;
  direction: ltr;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.store-Module {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
  gap: 15px;
  width: 100%;
  min-height: 300px;
}

.MuiDialog-container .MuiPaper-rounded::-webkit-scrollbar,
.store-Module::-webkit-scrollbar {
  display: none;
}

.store-Module,
.MuiDialog-container .MuiPaper-rounded {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.MuiDialog-container .MuiPaper-rounded .store-box {
  height: 200px !important;
  min-height: 0;
}

.branchesModal .MuiDialog-container .MuiPaper-rounded {
  max-width: 1000px !important;
  width: 95vw;
}

.module-heading__link {
  text-align: right;
  margin: 1% 0;
  padding: 1% 0;
  border-top: solid 2px var(--lightgray);
}

@media only screen and (max-width: 600px) {
  .store-Module {
    grid-template-columns: 1fr;
    padding: 2%;
  }
}

.store-Module__list {
  width: 100%;
  margin-bottom: 25px;
}

.store-box__info {
  margin: 5px 15px;
  display: flex;
  justify-content: space-between;
  min-height: 50px;
}

.category-box1 {
  border-radius: 5px;
  border: 1px solid #aaa;
}

.category-box1 h6 {
  color: #aaa;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.store-box__infoTop {
  display: flex;
  justify-content: space-evenly;
  /* flex-direction: column; */
  align-items: center;
  margin: 5px 15px;
  gap: 5px;
}

.store-box__brand {
  font-size: 16px;
  line-height: 14px;
  margin: 0;
}

.store-box__brand,
.address {
  text-align: right;
  margin-left: 5px;
}

.addressPhone {
  display: flex;
  justify-content: space-between;
}

.address,
.phone {
  color: #888;
  font-size: 12px;
}

.store-box__infoTop .MuiButton-root {
  height: 40px;
  line-height: 14px;
  max-width: 33%;
}

.store-box__name {
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  color: #555;
}

.store-box__likeBtn {
  position: absolute;
  right: 5px;
  top: 5px;
  background: #000;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  opacity: 0.4;
}

.store-box__kosher {
  position: absolute;
  right: 40px;
  top: 5px;
  background: rgb(0 0 0 / 40%);
  height: 30px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  color: white;
  font-size: 12px;
  /* opacity: 0.6; */
}

.store-box__likeBtn svg {
  height: 15px;
  width: 15px;
}

.store-box__likeBtn svg path {
  fill: #eee;
}

.imgWrapper {
  width: 50%;
  height: 100%;
  /* margin: auto; */
}

.productDetails-img {
  display: flex;
  justify-content: center;
}

.productDetails-img img {
  width: 90%;
  max-height: 250px;
  object-fit: cover;
  border-radius: 15px !important;
}

.sendToggleButton {
  width: 100px;
  font-size: 12px !important;
}

@media only screen and (max-width: 600px) {
  .imgWrapper {
    width: 100%;
  }

  .sendToggleButtonGroup {
    height: 50px;
    width: 100%;
  }

  .sendToggleButton {
    width: 25%;
    font-size: 12px !important;
  }

  .sendToggleButton svg {
    width: 18px;
  }

  .sendCheckBoxLabel span {
    font-size: 14px !important;
  }
}
