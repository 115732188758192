.centerTabs .TabsListUnstyled-horizontal {
  background-color: #eee;
  border-radius: 12px;
  margin: 0 auto;
  margin-bottom: 22px;
  max-width: 320px;
}

/* tab css */

.centerTabs .TabsListUnstyled-horizontal {
  background-color: #eee;
  border-radius: 12px;
  margin: 0 auto;
  margin-bottom: 22px;
  max-width: 320px;
}

.centerTabs .TabsListUnstyled-horizontal button {
  color: #666;
  font-size: 14px !important;
  padding: 6px 16px;
}

.centerTabs .TabsListUnstyled-horizontal button:hover {
  background-color: transparent;
}

.centerTabs .TabsListUnstyled-horizontal button.Mui-selected {
  color: var(--primary);
  background-color: #fff;
  box-shadow: 0px 0px 19px 0px rgb(0 0 0 / 10%);
}

.mainChat {
  background: var(--primary);
  height: 50px;
  width: 50px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #fff;
  box-shadow: 0 1px 15px 0 rgb(0 0 0 / 35%);
  color: #fff;
  cursor: pointer;
  z-index: 9;
}

.chatModel .MuiPaper-root {
  top: auto !important;
  left: auto !important;
  right: 30px;
  bottom: 95px;
  max-width: calc(100vw - 60px);
  width: 355px;
  overflow: hidden;
}

.chatingList {
  height: 45vh;
  overflow-y: scroll;
}

.chatingList::-webkit-scrollbar {
  width: 2px;
}

.chatingList::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 10px;
}

.chatingList::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.chatModel .centerTabs .TabsListUnstyled-horizontal button {
  font-size: 14px !important;
  margin: 5px 5px;
  padding: 5px 5px;
}

.chatModel .centerTabs .TabsListUnstyled-horizontal {
  max-width: 100%;
  min-width: 260px;
}

.chatMainBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 6px;
  border-radius: 6px;
  margin: 5px 0;
}

.chatMainBox:hover {
  background: #e1f9ff;
}

.chatMainBox--selected {
  background: #e1f9ff;
}

.chatMainBox__inner {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 5px;
}

.chatMainBox__img {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  margin-right: 12px;
  overflow: hidden;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 45px;
}

.chatMainBox__img img {
  height: 45px;
  width: 45px;
  object-fit: cover;
}

.chatMainBox__name {
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
}

.chatMainBox__text {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.chatMainBox__time {
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
  color: var(--primary);
}

.chatMainBox--active .chatMainBox__inner::after {
  content: "";
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: green;
  position: absolute;
  left: 1px;
  z-index: 1;
  top: 4px;
  display: block;
}

.chatHead {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 20px;
}

.chatHead__title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
}

.chatDetailsHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #e1f9ff;
  padding: 0 15px;
}

.chatDetailsHead-inner {
  display: flex;
  align-items: center;
}

.chatingList__date {
  text-align: center;
  margin: 12px 0;
  font-size: 16px;
  font-weight: 600;
}

.msgTime {
  display: flex;
  align-items: center;
  margin: 8px 0;
}

.msgTime__img {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  overflow: hidden;
}

.msgTime__img img {
  height: 35px;
  width: 35px;
  object-fit: cover;
}

.msgTime__text {
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  margin: 0;
  color: #999;
  margin: 0 10px;
}

.msgbox {
  padding: 10px;
  background: #ffe7d5;
  border-radius: 6px 0 6px 6px;
  font-size: 15px;
  color: #000;
}

.receiveBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.receiveBlock .msgbox {
  border-radius: 0 6px 6px 6px;
  background: #e1f9ff;
  margin-bottom: 5px;
}

.chatingList-details {
  padding: 10px 3px;
}

/* .chatDetailsBlock .TypeingBlock {
    margin: 0;
} */
.chatAccordion {
  box-shadow: 0px 1px 8px 0px rgb(0 0 0 / 12%);
  border-radius: 6px;
  margin: 10px 0;
}

.chatAccordion-Head {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
}

.chatAccordion-body {
  padding: 0 12px 12px;
}

.chatAccordion-body p {
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  margin: 0 0 5px;
}

.chatingList .categoryList {
  overflow: hidden;
  margin-top: 20px;
}

.chatingList .product-Module__list {
  margin-right: 15px;
}

.chatingList .product-box__img {
  height: 110px;
}

.product-box__cartBtn {
  background: var(--primary) !important;
  box-shadow: none !important;
  margin-top: 10px !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  border-radius: 8px !important;
}

.chatMainBox__imgGroup {
  display: flex;
}

.chatMainBox__imgGroup .chatMainBox__img {
  margin-left: -40px;
}

.chatMainBox__imgGroup .chatMainBox__img:first-child {
  margin-left: 0px;
}

p .chatDetailsHead {
  margin-top: -16px;
  margin-left: -16px;
  width: calc(100% + 32px);
}

.chatSearch {
  width: 100%;
  padding: 10px 0;
}

.chatSearch .search-container {
  width: 100%;
  max-width: 100%;
  margin: 0;
}

.chatSearch .search-container__input {
  background: transparent;
}

.circleCheckbox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circleCheckbox svg {
  display: none;
}

.circleCheckbox::before {
  content: "";
  height: 18px;
  width: 18px;
  border: 2px solid #999;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  transition: all 0.3s ease;
}

.circleCheckbox.Mui-checked::before {
  border: 2px solid var(--primary);
  background: var(--primary);
}

.addedUserBlock {
  background: #eee;
  margin-left: -16px;
  width: calc(100% + 32px);
  padding: 0 10px;
  display: flex;
  position: relative;
  padding-right: 60px;
}

.addedUser {
  position: relative;
  margin: 10px 6px;
}

.addedUser img {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  object-fit: cover;
}

.addedUser span {
  background: #fff;
  height: 20px;
  width: 20px;
  position: absolute;
  right: 0;
  border-radius: 50%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addedUser span svg {
  height: 16px;
  width: 16px;
  margin-top: 1px;
  margin-left: 1px;
}

.addedUserBlockBtn {
  position: absolute;
  right: -60px;
  top: 0;
  bottom: 0;
  background: #eee;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addedUserBlockBtn button {
  margin: 0 !important;
  height: 45px;
  width: 45px;
  padding: 0;
  min-width: 45px;
}

.createGroupBtn {
  margin-top: 20px;
  text-align: center;
}

.createGroupBlock {
  padding: 15px 0;
  display: flex;
}

.createGroupBlock-nameText {
  font-size: 14px;
  color: #888;
  margin-top: 5px;
}

.createGroupBlock input {
  width: 100%;
}

.createGroupBlock-name,
.createGroupBlock-name .MuiFormControl-root {
  width: 100%;
}

.chatingmember {
  height: 40vh;
}

[dir="rtl"] p .chatDetailsHead {
  margin-top: -16px;
  margin-right: -16px;
  margin-left: 0;
  width: calc(100% + 32px);
}

[dir="rtl"] .chatDetailsHead-inner svg {
  transform: rotate(180deg);
}

[dir="rtl"] .chatMainBox__imgGroup .chatMainBox__img {
  margin-left: 12px;
  margin-right: -40px;
}

[dir="rtl"] .chatMainBox__imgGroup .chatMainBox__img:first-child {
  margin-left: 12px;
  margin-right: 0px;
}

[dir="rtl"] .search-container__input {
  padding-left: 10px;
  padding-right: 35px;
}

[dir="rtl"] .search-container__btn {
  left: auto;
  right: 10px;
}

[dir="rtl"] .mic-container__btn {
  right: auto;
  left: 10px;
}

[dir="rtl"] .addedUserBlock {
  margin-right: -16px;
  margin-left: 0;
}

[dir="rtl"] .addedUserBlockBtn {
  left: -10px;
  right: auto;
}

[dir="rtl"] .addedUser span {
  right: auto;
  left: -2px;
}

[dir="rtl"] .createGroupBlock-nameText {
  text-align: right;
}

.closeChatBtn {
  display: none;
}

.closeChatBtn svg {
  height: 16px;
  width: 16px;
}

@media only screen and (max-width: 991px) {
  .mainChat {
    background: var(--primary);
    right: 20px;
  }

  .chatModel .MuiPaper-root {
    right: 20px;
    max-width: calc(100vw - 40px);
  }
}

@media only screen and (max-width: 600px) {
  .chatModel .MuiPaper-root {
    width: auto;
    right: 0;
    left: 0 !important;
    max-width: 100%;
    top: 0 !important;
    bottom: 0;
  }

  .agentChatFooter {
    position: fixed;
    bottom: 15px;
    left: 20px;
    right: 20px;
  }

  .chatingList {
    height: 58vh;
  }

  .chatingList--inner {
    height: 66vh;
  }

  .chatingList--add {
    height: 70vh;
  }

  .closeChatBtn {
    position: absolute;
    right: 5px;
    top: 5px;
    border: 1px solid var(--primary);
    border-radius: 50%;
    background: #fff;
    height: 22px;
    width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .chatHead {
    padding-right: 8px;
  }

  .chatDetailsHead {
    padding-right: 22px;
  }
}
