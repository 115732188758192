.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #00cae9;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}

.location {
  display: flex;
}

.location .locationDetails {
  margin: 5px;
}

.location .locationDetails .city {
  /* font-weight: bold !important; */
  font-size: 14px !important;
}

.product-box__discount_marker {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
}

.product-box__off {
  /* background: #27739f;
  padding: 8px 15px;
  color: #fff;
  display: inline-block;
  border-radius: 100px; */
}
