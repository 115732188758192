.MuiDialogContent-root {
  padding: 10px 30px !important;
}

.checkoutBlocks {
  max-width: 640px;
  margin: 0 auto;
  /* direction: rtl; */
  text-align: right;
}

.checkoutBlocks-title {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 15px;
  text-align: center;
}

.LinkBtn {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #333 !important;
  text-transform: uppercase !important;
  margin-top: 20px !important;
}

.checkoutBlocks .MuiFormControl-root {
  width: 100%;
  margin: 4px 0;
}

.PaymentCards {
  border: 1px solid #ccc;
  background: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin: 5px 0;
  position: relative;
  border-radius: 5px;
}

.PaymentCards__delete {
  /* position: absolute; */
  right: 0;
  bottom: 3px;
  font-size: 18px !important;
  color: red;
  transition: opacity 0.4s;
}

.PaymentCards__delete:hover {
  opacity: 0.7;
}

.PaymentCards__selected {
  outline: 2px solid #ccc;
}

.PaymentCards-img {
  width: 50px;
  height: 25px;
  margin-right: 15px;
}

.PaymentCards-img img {
  width: 60px;
  height: 25px;
  object-fit: contain;
}

.PaymentCards-no {
  direction: ltr;
  font-size: 16px;
  color: #333;
  overflow-wrap: anywhere;
}

.checkoutFooterBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 25px 0 0;
}

.checkoutFooterBtn button {
  max-width: 280px;
  margin: 0 auto;
  border-radius: 12px;
  background-color: var(--primary) !important;
  color: white !important;
  padding: 15px 12px;
  text-transform: capitalize;
  font-size: 20px;
  line-height: 24px;
  border: 1px solid var(--primary);
  width: 100%;
}

.cardTextNo {
  display: flex;
  align-items: center;
  position: relative;
}

.cardTextNo img {
  position: absolute;
  right: 10px;
  width: 50px;
}

.checkoutBlocks-mainImg {
  max-width: 400px;
  height: 400px;
  margin: 0 auto;
}

.checkoutBlocks-mainImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  scale: 1.3;
}

[dir="rtl"] .checkoutBlocks-title {
  text-align: center;
}

[dir="rtl"] .checkoutBlocks label {
  right: 25px;
  left: unset;
}
