.ClubRegistrImg {
  opacity: 0.2;
  height: 80%;
  border-radius: 25px;
}

.ClubRegistrContent {
  position: absolute;
  padding: 30px;
  color: black;
}

.card {
  width: 300px;
  border-radius: 15px;
  transform: rotate(-10deg);
  box-shadow: 0px 0px 20px 0px rgb(42 134 159 / 20%);
  margin: auto;
}

.links {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.text-reset {
  cursor: pointer;
  margin-bottom: 5px;
}

@media only screen and (max-width: 500px) {
  .ClubRegistrImg {
    height: 87%;
    object-fit: cover;
  }

  .clubRegister h1 {
    font-size: 1.5rem;
  }

  .clubRegister .ClubRegistrContent p {
    font-size: 0.8rem;
    padding: 0 15px;
  }

  .clubRegister .ClubRegistrContent form {
    padding: 0 !important;
  }

  .clubRegister .ClubRegistrContent input {
    font-size: 0.8rem;
  }

  .clubRegister .ClubRegistrContent button {
    font-size: 0.7rem;
  }

  .card {
    width: 200px;
  }
}

.sidebarTamplate .mobile {
  flex-direction: column-reverse !important;
}

.sidebarTamplate .col-sm-3,
.sidebarTamplate .col-sm-6 {
  max-width: 100% !important;
}

.sidebarModule .clubRegister h1 {
  font-size: 1.5rem !important;
}

.sidebarModule .clubRegister .ClubRegistrContent p {
  font-size: 0.8rem !important;
}

.sidebarModule .clubRegister .ClubRegistrContent form {
  padding: 0 !important;
}

.sidebarModule .clubRegister .ClubRegistrContent input {
  font-size: 0.8rem !important;
}

.sidebarModule .clubRegister .ClubRegistrContent button {
  font-size: 0.7rem !important;
}

.sidebarModule .card {
  width: 200px !important;
}
