.paymentTemplate {
  padding: 60px 0;
}
.paymentCard {
  width: 100%;
  margin: 50px auto 0;
  padding: 40px 22px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgb(42 134 159 / 10%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  max-width: 450px;
}
.paymentCard-text {
  color: #888;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  margin: 0 0 30px;
}

.paymentCard-code svg {
  font-size: 256px;
}
