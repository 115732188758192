.container {
  background-color: #eee;
  border-bottom: 1px solid #cacaca;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100500;
}

@media (max-width: 480px) {
  .container {
    height: 50px;
  }
}

.description {
  margin-right: 10px;
  display: flex;
  align-content: center;
  align-items: center;
  line-height: 1;
}

.iosButton {
  padding: 0 10px;
  height: 21px;
}

.notIosButton {
  height: 28px;
  padding: 0 15px;
  line-height: 1;
  font-size: 15px;
}

.close {
  width: 10px;
  height: 10px;
  cursor: pointer;
  right: 0;
  position: absolute;
  user-select: none;
  transition: all 0.4s;
}

.close:hover {
  opacity: 0.7;
}

@media (max-width: 480px) {
  .close {
    position: static;
    margin-left: 10px;
    min-width: 10px;
  }
}
