.userProfile .top {
  justify-content: space-between;
  padding: 0 5px;
}

.userProfile .profile-left,
.userProfile .profile-right,
.userProfile .top {
  display: flex;
  align-items: center;
}

.userProfile .top svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.userProfile .top span {
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

.profile-right svg {
  margin-right: 0;
  margin-left: 10px;
}

.profile-right span {
  color: gray !important;
  font-weight: 400 !important;
}

.userProfile .user-img {
  width: 50px;
  height: 50px;
}

.userProfile .userProfile-info {
  padding-top: 30px;
}

.userProfile-infoTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.userProfile-infoHead {
  display: flex;
  align-items: center;
}

.graphMainCard {
  padding: 18px;
  width: 100%;
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 13px 0px rgb(0 0 0 / 15%);
  border-radius: 10px;
}

.graphMainCard div {
  justify-content: space-around;
}

.userProfile .data {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userProfile .btn-btm {
  background-color: #ebebeb;
  padding: 10px;
  width: 100%;
  margin-top: 15px;
  border-radius: 12px;
}

.userProfile .value {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  width: 100%;
  box-shadow: 0px 0px 13px 0px rgb(0 0 0 / 5%);
  margin: 20px 0;
}

.userProfile .value svg {
  width: 50px;
  height: 50px;
  fill: var(--primary);
}

.userProfile .value-left {
  display: flex;
  flex-direction: column;
}

.userProfile .value-left :nth-child(1) {
  color: gray;
  font-size: 0.7rem;
}

.userProfile .value-left :nth-child(2) {
  color: #284b75;
  font-size: 1.6rem;
}

.userProfile .value-left :nth-child(3) {
  color: var(--primary);
  font-size: 1rem;
}

.userProfile .center-col {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  height: 150px;
  box-shadow: 0px 0px 13px 0px rgb(0 0 0 / 5%);
  border-radius: 10px;
  text-align: center;
}

.userProfile .center-col svg {
  width: 50px;
  height: 50px;
  fill: var(--primary);
}

.center-col-Title {
  font-size: 14px;
  line-height: 22px;
  color: #454545;
  font-weight: 400;
  margin-top: 10px;
}

.userProfile .box-list {
  display: grid;
  grid-gap: 15px;
  gap: 15px;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.userProfile-container {
  padding: 0 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.userProfile-infosettings {
  background: #eee;
  border: 1px solid #eee;
  padding: 5px 10px;
  font-size: 14px;
  color: #000;
  font-weight: 400;
  border-radius: 8px;
}

.userProfile-infoname {
  font-size: 20px;
  line-height: 26px;
  color: #000;
  font-weight: 500;
  margin-bottom: 3px;
}

.userProfile-infojob {
  font-size: 14px;
  line-height: 22px;
  color: #888;
  font-weight: 400;
  margin-bottom: 0;
}

.userProfile-infoContent {
  font-size: 14px;
  line-height: 22px;
  color: #888;
  font-weight: 400;
  margin-bottom: 0;
}

.userProfile-infoImage {
  width: 70px;
  height: 70px;
  margin-right: 15px;
  box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 8%);
  border-radius: 10px;
  overflow: hidden;
}

.userProfile-infoImage img {
  width: 70px;
  height: 70px;
  object-fit: contain;
}

.graphMainCard-Btn {
  background-color: #ebebeb;
  width: 100%;
  padding: 12px 20px;
  border-radius: 10px;
  border: none;
  font-size: 15px;
  color: #000;
  font-weight: 500;
  margin-top: 20px;
}

.userProfile .data-text {
  font-size: 14px;
  line-height: 22px;
  color: #888;
  font-weight: 400;
}

.userProfile .data-circle {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  padding: 5px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  margin: 10px 0;
}

.userProfile .circle-1 {
  border-top: 5px solid #7de0a8;
  border-right: 5px solid #7de0a8;
  border-bottom: 5px solid #7de0a8;
  color: #7de0a8;
}

.userProfile .circle-2 {
  border-right: 5px solid var(--primary);
  color: var(--primary);
}

.userProfile .circle-3 {
  border-bottom: 5px solid #f91a1d;
  border-right: 5px solid #f91a1d;
  color: #f91a1d;
}
