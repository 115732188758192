.prices {
  display: flex;
  flex-direction: column;
  justify-content: center;
  direction: rtl;
}

.prices .priceDetails {
  display: flex;
  justify-content: center;
  text-align: right;
}

.prices .priceDetails .half {
  width: 45%;
  margin: 5%;
}

.prices .priceToggle {
  font-size: 33px !important;
  font-weight: bold !important;
  width: 250px;
  /* padding: 5% !important; */
}

.prices .MuiDataGrid-footerContainer {
  display: none;
}

.prices .cellImage {
  /* height: 80px !important; */
  padding: 0 !important;
  display: flex;
  justify-content: center;
}

.prices .cellImage img {
  height: 100%;
  min-width: 100%;
  object-fit: cover;
  /* object-position: center !important; */

  /* margin: 5%; */
}

.prices .cellTitle {
  font-weight: bold;
}

/* .cellPrice {} */
