.cluub-block {
  margin-bottom: 50px;
  direction: ltr;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.club-Module {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  padding: 2%;
}

.club-box {
  width: 100%;
  height: 150px;
  display: flex;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 1px 15px 0 rgb(0 0 0 / 35%);
  cursor: pointer;
}

.club-box__img {
  width: 100%;
  object-fit: contain;
}

@media only screen and (max-width: 900px) {
  .club-Module {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 600px) {
  .club-Module {
    grid-template-columns: 1fr;
  }
}
