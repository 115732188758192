/*TODO: add custom styles, currently it is ripped from ProMall using very basic html styles.*/
.elementor {
  text-align: right;
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

.sub {
  padding: 0 20px !important;
  /* margin: 0; */
  font-size: smaller;
}
