html {
  --gray: #bdc4d1;
  --lightgray: #e1e4ea;
  --primary: #009adf !important;
  /* --primary: #00c4f8 !important; */
  /* --lightblue: #17A2B8; */
  --blue: #009adf !important;
  /* --blue3: #007bff;
    --secondary: #1AABE3;
    --blue2: #00a9d6;
    --dark: #00374d; */
}

#root {
  height: 100% !important;
}

body {
  overflow-x: hidden;
  padding: 0 !important;
}

html {
  overflow-x: hidden;
}

a:hover {
  text-decoration: none;
}

.App {
  text-align: center;
}

​.main {
  overflow-x: hidden;
}

iframe {
  z-index: 0 !important;
  /* height: 0 !important;
  width: 0 !important; */
  position: initial !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.cookie-margin {
  justify-content: center !important;
  bottom: 38px !important;
}

.cookie {
  justify-content: center !important;
}

.cookie .cookieText {
  text-align: center;
  max-width: 400px;
  align-items: center !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

​ .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

​ .App-link {
  color: #61dafb;
}

.MuiPopover-root .MuiPopover-paper .MuiListItem-gutters.MuiListItem-button img {
  margin-right: 8px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.flex {
  display: flex;
}

/* RTL CSS */
[dir="rtl"] .module-heading__title {
  text-align: right;
  font-size: 17px;
  line-height: 20px;
}

[dir="rtl"] .module-heading__link {
  font-size: 17px;
  line-height: 20px;
}

[dir="rtl"] .product-box__brand {
  font-size: 15px;
}

[dir="rtl"] .category-box__title {
  font-size: 16px;
}

[dir="rtl"] .vendorContent-text {
  font-size: 16px;
}

[dir="rtl"] .centerTabs .TabsListUnstyled-horizontal button {
  font-size: 17px !important;
}

[dir="rtl"] .blueBtn,
[dir="rtl"] .pageHead-Btn,
[dir="rtl"] .userMainHead-btn,
[dir="rtl"] .filterBlockTitle {
  font-size: 16px !important;
  font-family: inherit;
}

[dir="rtl"] .vendorbanner-brandTitle {
  font-size: 18px;
}

[dir="rtl"] .iconListBox-title,
[dir="rtl"] .progressBox-title,
[dir="rtl"] .userMainHead-text,
[dir="rtl"] .userMainHead-subTitle,
/* [dir="rtl"] .categoriesSliderTabs button, */
[dir="rtl"] .graphBlock-Btn {
  font-size: 15px;
}

[dir="rtl"] .LinkBtn {
  font-size: 18px !important;
}

[dir="rtl"] .graphBox {
  font-size: 14px;
}

[dir="rtl"] .module-heading__content {
  font-size: 16px;
}

[dir="rtl"] .vendorbanner-brandSubtitle {
  /* font-size: 17px; */
}

[dir="rtl"] .product-box__name {
  font-size: 16px;
  text-align: right;
}

[dir="rtl"] .balanceBox {
  text-align: right;
}

[dir="rtl"] .caseback-box {
  text-align: right;
}

[dir="rtl"] .text-right {
  /* text-align: left !important; */
}

[dir="rtl"] .product-box__infoTop {
  flex-direction: row-reverse;
  text-align: right;
}

[dir="rtl"] .vendorbanner-brandImg {
  margin-right: 0px;
  margin-left: 20px;
}

[dir="rtl"] .vendorContent {
  text-align: right;
}

[dir="rtl"] .productDetails-brandImage {
  margin-right: 0px;
  margin-left: 12px;
}

[dir="rtl"] .productDetails-content {
  text-align: right;
}

[dir="rtl"] .productDetails-List {
  margin: 30px 0;
  text-align: right;
}

[dir="rtl"] .filterBlockTitle {
  text-align: right;
}

[dir="rtl"] .filterBlockRow span {
  margin-left: 10px;
  margin-right: 0px;
}

[dir="rtl"] .userMainHead-image {
  margin-left: 12px;
  margin-right: 0px;
}

[dir="rtl"] .userMainHead-title {
  text-align: right;
}

[dir="rtl"] .userMainHead-subTitle {
  text-align: right;
}

[dir="rtl"] .pageHead-title svg {
  margin-left: 12px;
  margin-right: 0px;
}

[dir="rtl"] .CheckLinkBlock .MuiCheckbox-root {
  margin-left: 8px;
  margin-right: 0px;
}

[dir="rtl"] .cartProduct-box__img {
  margin-left: 25px;
  margin-right: 0px;
}

[dir="rtl"] .cartProduct-box__info {
  text-align: right;
}

[dir="rtl"] .category-box {
  margin: 5px 2px 5px 12px;
}

[dir="rtl"] .menuList-img {
  margin-left: 15px;
  margin-right: 0px;
}

[dir="rtl"] .menufooterLink svg {
  margin-left: 8px;
  margin-right: 0px;
}

[dir="rtl"] .menuList li a {
  text-align: right;
}

[dir="rtl"] .menuUser-img {
  margin-left: 15px;
  margin-right: 0px;
}

[dir="rtl"] .closeBtn {
  right: auto;
  left: 15px;
}

[dir="rtl"] .cartProduct-brandImage {
  margin-left: 12px;
  margin-right: 0px;
}

[dir="rtl"] .removeBtn {
  margin-left: 0px;
  margin-right: 25px;
}

[dir="rtl"] .PaymentCards-img {
  margin-left: 15px;
  margin-right: 0px;
}

[dir="rtl"]
  .MuiPopover-root
  .MuiPopover-paper
  .MuiListItem-gutters.MuiListItem-button
  img {
  margin-right: 0;
  margin-left: 8px;
}

[dir="rtl"] .userMainHead-Inner {
  text-align: right;
}

[dir="rtl"] .appProductDetails-brandImg {
  margin-left: 0px;
  margin-right: 12px;
}

[dir="rtl"] .appProductDetails-Date svg {
  margin-left: 10px;
  margin-right: 0px;
}

[dir="rtl"] .css-1d6wzja-MuiButton-startIcon {
  margin-right: 0;
  margin-left: 8px;
}

[dir="rtl"] .css-9tj150-MuiButton-endIcon {
  margin-right: 8px;
  margin-left: 0;
}

[dir="rtl"] .barndAuth-img {
  margin-left: 0;
  margin-right: 12px;
}

[dir="rtl"] .agentProfileBox-heading {
  margin-right: 15px;
  margin-left: 0px;
  text-align: right;
}

[dir="rtl"] .sugession-AnsBtn {
  margin-left: 8px;
  margin-right: 0px;
}

[dir="rtl"] .sendBlockOptions button {
  margin-right: 10px;
  margin-left: 0px;
}

[dir="rtl"] .settings .userBlock-img {
  margin-left: 15px;
  margin-right: 0;
}

[dir="rtl"] .userBlock-Info,
[dir="rtl"] .right-sett {
  text-align: right;
}

[dir="rtl"] .settingsListItem-icon {
  margin-right: 0;
  margin-left: 10px;
}

[dir="rtl"] .registerForm-loginBtn {
  margin-left: 15px !important;
  margin-right: 0px !important;
}

[dir="rtl"] .mainChat {
  left: 30px;
  right: auto;
}

[dir="rtl"] .chatModel .MuiPaper-root {
  left: 30px !important;
  right: auto;
}

[dir="rtl"] .chatMainBox__img {
  margin-right: 0px;
  margin-left: 12px;
}

[dir="rtl"] .chatMainBox__info {
  text-align: right;
}

[dir="rtl"] .chatMainBox--active .chatMainBox__inner::after {
  left: auto;
  right: 1px;
}

[dir="rtl"] .notificationList .chatMainBox__info {
  text-align: right;
}

.MuiMenuItem-gutters,
.MuiSelect-select,
.MuiSelect-nativeInput,
.Mui-selected,
.MuiInputLabel-root,
.MuiOutlinedInput-input,
.inputStyle,
.MuiButton-root,
.MuiButton-outlined,
.MuiButton-outlinedPrimary,
.MuiButton-sizeMedium,
.MuiButton-outlinedSizeMedium,
.MuiButtonBase-root,
.MuiInput-input,
.MuiInputBase-input,
.MuiInput-root,
.MuiInput-underline,
.MuiInputBase-root,
.MuiInputBase-colorPrimary,
.MuiInputBase-formControl,
.MuiTypography-root,
.MuiTypography-body1,
.MuiFormControlLabel-label,
.MuiSlider-markLabel {
  /* direction: inherit !important; */
  font-family: inherit !important;
}

.activeChat {
  overflow: hidden;
  height: 95vh;
}

@media only screen and (max-width: 991px) {
  [dir="rtl"] .vendorbanner-brandImg {
    margin-right: 0px;
    margin-left: 0px;
  }

  [dir="rtl"] .mainChat {
    left: 20px;
    right: auto;
  }

  [dir="rtl"] .chatModel .MuiPaper-root {
    left: 20px !important;
  }
}

@media only screen and (max-width: 600px) {
  .cookie {
    bottom: 50px !important;
  }

  .cookie .cookieText {
    max-width: 60%;
  }

  [dir="rtl"] .removeBtn {
    margin-left: 0px;
    margin-right: 0;
  }
}
