.dashboard-tamplate {
  /* padding-bottom: 20px; */
  overflow-x: hidden;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/* --- Balance Module css --- */
.balance-module {
  background-color: #888;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 25px;
}

div[data-arrow="left"],
div[data-arrow="right"] {
  display: none;
}

.balance-module__title {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  margin: 0 0 5px;
}

.balance-module__price {
  font-size: 24px;
  color: #fff;
  margin: 0;
  font-weight: 400;
}

.balance-module__price span {
  font-weight: 600;
}

.balance-module__btn {
  background-color: #c9c9c9;
  border-radius: 5px;
  padding: 15px;
  height: 35px;
  width: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.balance-module__btn svg path {
  fill: #555;
}

/* --- Module Heading css --- */
.module-heading {
  margin-bottom: 10px;
}

.module-heading__title {
  font-size: 24px;
  line-height: 18px;
  color: #77838f;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.module-heading__link {
  font-size: 12px;
  line-height: 17px;
  /* color: var(--primary); */
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* --- Block Slider --- */
.block-slider {
  margin: 25px 0;
}

.categoryList {
  display: flex;
  overflow-x: scroll;
}

.category-box {
  border-radius: 15px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  padding: 25px 15px;
  width: 144px;
  box-shadow: 0px 1px 8px 0px rgb(0 0 0 / 12%);
  margin: 5px 12px 5px 2px;
}

.category-box::before {
  content: "";
  height: 45%;
  width: 100%;
  background: #8e8e8e;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  z-index: 0;
}

.category-box__img {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  border: 3px solid #fff;
  margin: 0 auto;
  margin-bottom: 8px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 8%);
}

/* .category-box__active {
  text-decoration: underline;
} */

.category-box__img > img {
  background-color: #fff;
  object-fit: contain;
  object-position: center;
  height: 100%;
  width: 100%;
  padding: 10%;
}

/* .category-box__title {
  font-size: 16px;
  font-weight: 700;
  color: #333;
  margin: 0;
  height: 30px;
} */

/* --- Product Tabs --- */
.product-block {
  margin-bottom: 50px;
  direction: rtl;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-Module {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 15px;
  gap: 15px;
  width: 100%;
}

.product-Module__list {
  width: 100%;
  /* margin-bottom: 25px; */
  height: 100%;
}

.product-box__info {
  margin-top: 10px;
  text-align: right;
}

.product-box__brand {
  font-size: 15px;
  line-height: 14px;
  margin: 0;
  min-width: 30%;
  /* text-align: right; */
}

.product-box__desc {
  color: #888;
  font-size: 15px;
  line-height: 25px;
  margin: 20px 0;
  height: 150px;
  /* min-width: 30%; */
  /* text-align: right; */
}

.product-box__price {
  font-size: 20px;
  color: #555;
  line-height: 20px;
  font-weight: 600;
}

.price-input {
  width: 25%;
  margin-left: 5px;
}

.product-box__infoTop .MuiButton-root {
  height: 40px;
}

/* MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButtonBase-root  */
.product-box__name {
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  color: #555;
}

.product-box__img {
  width: 100%;
  min-width: 150px;
  height: 150px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0.2);
}

.globalImg {
  height: 220px;
}

.global {
  grid-row: 1 / 3 !important;
}

.product-box__likeBtn {
  position: absolute;
  right: 10px;
  top: 10px;
  background: #000;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  opacity: 0.4;
}

.product-box__likeBtn svg {
  height: 15px;
  width: 15px;
}

.product-box__likeBtn svg path {
  fill: #eee;
}

.product-box__tag {
  position: absolute;
  left: 10px;
  bottom: -5px;
  /* height: 40px;
  padding: 10px;
  width: 40px; */
  box-sizing: content-box;
}

.product-box__tag img {
  width: 100%;
  border-radius: 5px !important;
  border: none;
}

.shach {
  right: 50px;
}

.best {
  top: 80px;
  color: black;
  background: gold;
  border-radius: 5px;
  /* height: 30px; */
  padding: 5px 10px;
  font-weight: bold;
  width: 220px;
  text-align: center;
  line-height: 40px;
}

.product-box__discount {
  font-size: 12px;
  line-height: 10px;
  z-index: 10;
  width: 100px;
}

.product-box__off {
  background: #27739f;
  padding: 10px 5px;
  text-align: center;
  color: #fff;
  border-radius: 100px;
  position: absolute;
  left: 60px;
  top: 5px;
  z-index: 1;
  width: 80px;
}

.product-box__limitedOffer {
  background: #fff;
  padding: 8px 15px;
  color: #ff6302;
  display: inline-block;
  border-radius: 100px;
}

.product-box__discount .time {
  color: #000;
  margin-right: 3px;
}

/* --- Featured Product Block css --- */
.featuredProduct-box {
  border-radius: 15px;
  height: 160px;
  padding: 20px 15px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 25px;
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.featuredProduct-box:after {
  content: "";
  height: 50%;
  width: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6867121848739496) 0%,
    rgba(255, 182, 182, 0) 100%
  );
  position: absolute;
  left: 0;
  bottom: 0;
}

.featuredProduct-box__title {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  line-height: 28px;
  margin: 0;
  position: relative;
  z-index: 10;
}

.categoriesSliderTabs {
  margin-bottom: 15px;
  min-height: 0 !important;
}

.categoriesSliderTabs .MuiTabs-scroller {
  overflow-x: scroll !important;
}

.MuiTab-iconWrapper {
  width: 20px;
  height: 20px;
}

.categoriesSliderTabs button {
  padding: 5px 5px !important;
  height: auto !important;
  min-width: 0;
  min-height: auto;
  border-radius: 50px;
  overflow: hidden;
  margin-right: 8px;
  font-size: 12px;
  /* font-weight: 600; */
  color: #888;
  text-transform: capitalize;
  font-family: inherit;
}

.categoriesSliderTabs button.activetab {
  background-color: #06090a;
  color: rgb(8, 8, 8);
}

.caseback-box {
  height: 160px;
  width: 100%;
  background-color: gray;
  border-radius: 12px;
  text-align: left;
  color: white;
  display: flex;
  align-items: center;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

.caseback-boxInner {
  padding: 18px;
  width: 100%;
  height: 100%;
  background: #00000050;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.caseback-center {
  /* display: grid; */
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
  gap: 15px;
  margin: 20px 0 35px;
}

.caseback-center .styles-module_slider__o0fqa {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.casebackBox-Title {
  margin: 0;
  font-size: 24px;
  line-height: 28px;
  color: #fff;
  font-weight: 500;
}

.casebackBox-subTitle {
  margin: 0;
  font-size: 24px;
  line-height: 28px;
  color: #fff;
  font-weight: 300;
}

.casebackBox-offer {
  font-size: 16px;
  font-weight: 400;
  margin: 8px 0 0;
}

.casebackBox-offer span {
  font-size: 28px;
  font-weight: 700;
}

.balanceBox {
  height: 40vh;
  width: 100%;
  background-color: gray;
  border-radius: 12px;
  text-align: left;
  color: white;
  display: flex;
  align-items: center;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  /* margin-bottom: 5%; */
}

.headTitle {
  color: white;
  font-size: 10vw;
  font-weight: 900;
  text-align: center;
}

.balanceBoxInner {
  padding: 18px;
  width: 100%;
  height: 100%;
  background: #00000050;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.balanceBoxTitle {
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  color: #fff;
}

.balanceBoxprice {
  margin: 5px 0 0;
  font-size: 24px;
  line-height: 28px;
  color: #fff;
  font-weight: 600;
}

.allCategory {
  /* display: grid; */
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
  gap: 15px;
  margin: 20px 0;
}

.allCategory .styles-module_slider__o0fqa {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.paginationNav {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
}

.paginationNav {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
}

/*------------------------------ pagination color --------------*/
.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: var(--primary) !important;
}

.css-1hxrwmy-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: var(--primary) !important;
}

[dir="rtl"] .product-box__price {
  direction: ltr;
  text-align: left;
}

@media only screen and (max-width: 991px) {
  .product-Module {
    grid-template-columns: 1fr 1fr;
  }

  /* @media only screen and (max-width: 600px) {
  .product-Module {
    grid-template-columns: 1fr;
  } */

  .caseback-box,
  .featuredProduct-box {
    width: 275px;
  }

  .caseback-center .styles-module_slider__o0fqa,
  .allCategory .styles-module_slider__o0fqa {
    display: flex;
  }

  .caseback-box,
  .allCategory .featuredProduct-box {
    width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .product-Module {
    grid-template-columns: 1fr;
  }
}

/* checking */

.otherCategories {
  margin-bottom: 20px;
}

.otherCategories .swiper-wrapper {
  justify-content: space-between;
  padding-inline-start: 30px;
}

.otherCategories .swiper-slide {
  width: 100px !important;
}

.otherCategoryTitle {
  font-size: 0.7rem;
}

[dir="rtl"] .otherCategories .swiper-slide {
  /* width: 85px; */
}

.otherCategory {
  text-align: center;
}

.otherCategory svg {
  font-size: 35px;
}

@media only screen and (max-width: 500px) {
  .balanceBox {
    height: 30vh;
  }
}
