.filter {
  flex-direction: row !important;
  width: 100%;
  justify-content: space-between;
}

.filter .filterBlock {
  display: flex;
  flex-direction: column;
  border: none;
  /* width: 300px; */
  align-items: center;
  justify-content: space-evenly;
}

.filter .filterBlock .priceSlider {
  width: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.filter .filterBlock .priceSlider .priceLabel {
  color: white;
  background-color: var(--primary);
  padding: 0 5px;
  border-radius: 5px;
  direction: ltr;
}

.filter .searchWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 500px) {
  .filter .searchWrapper {
    justify-content: center;
  }
}

.filter .searchWrapper .search-container {
  width: 100%;
  height: 5px;
}
