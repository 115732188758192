.elementor-4988 {
  direction: rtl;
  text-align: right;
}

.container strong {
  text-align: center;
}

.contact {
  display: flex;
}

.contact .formBox {
  width: 50%;
  margin: 5%;
}

.contact .formBox h6 {
  font-weight: bold;
  margin-top: 30px;
}

@media screen and (max-width: 600px) {
  .contact {
    flex-direction: column;
  }
  .contact .formBox {
    width: auto;
    margin: 5%;
  }
}
