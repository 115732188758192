@font-face {
  font-family: "Open Sans", arial;
  src: url("./assets/fonts/OpenSans/OpenSans-SemiBoldItalic.eot");
  src: local("Open Sans SemiBold Italic"), local("OpenSans-SemiBoldItalic"),
    url("./assets/fonts/OpenSans/OpenSans-SemiBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/OpenSans/OpenSans-SemiBoldItalic.woff2") format("woff2"),
    url("./assets/fonts/OpenSans/OpenSans-SemiBoldItalic.woff") format("woff"),
    url("./assets/fonts/OpenSans/OpenSans-SemiBoldItalic.ttf")
      format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans", arial;
  src: url("./assets/fonts/OpenSans/OpenSans-LightItalic.eot");
  src: local("Open Sans Light Italic"), local("OpenSans-LightItalic"),
    url("./assets/fonts/OpenSans/OpenSans-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/OpenSans/OpenSans-LightItalic.woff2") format("woff2"),
    url("./assets/fonts/OpenSans/OpenSans-LightItalic.woff") format("woff"),
    url("./assets/fonts/OpenSans/OpenSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans", arial;
  src: url("./assets/fonts/OpenSans/OpenSans-Bold.eot");
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
    url("./assets/fonts/OpenSans/OpenSans-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/OpenSans/OpenSans-Bold.woff2") format("woff2"),
    url("./assets/fonts/OpenSans/OpenSans-Bold.woff") format("woff"),
    url("./assets/fonts/OpenSans/OpenSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans", arial;
  src: url("./assets/fonts/OpenSans/OpenSans-ExtraBoldItalic.eot");
  src: local("Open Sans ExtraBold Italic"), local("OpenSans-ExtraBoldItalic"),
    url("./assets/fonts/OpenSans/OpenSans-ExtraBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/OpenSans/OpenSans-ExtraBoldItalic.woff2")
      format("woff2"),
    url("./assets/fonts/OpenSans/OpenSans-ExtraBoldItalic.woff") format("woff"),
    url("./assets/fonts/OpenSans/OpenSans-ExtraBoldItalic.ttf")
      format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans", arial;
  src: url("./assets/fonts/OpenSans/OpenSans-Light.eot");
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url("./assets/fonts/OpenSans/OpenSans-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/OpenSans/OpenSans-Light.woff2") format("woff2"),
    url("./assets/fonts/OpenSans/OpenSans-Light.woff") format("woff"),
    url("./assets/fonts/OpenSans/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans", arial;
  src: url("./assets/fonts/OpenSans/OpenSans-Italic.eot");
  src: local("Open Sans Italic"), local("OpenSans-Italic"),
    url("./assets/fonts/OpenSans/OpenSans-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/OpenSans/OpenSans-Italic.woff2") format("woff2"),
    url("./assets/fonts/OpenSans/OpenSans-Italic.woff") format("woff"),
    url("./assets/fonts/OpenSans/OpenSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans", arial;
  src: url("./assets/fonts/OpenSans/OpenSans-Regular.eot");
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url("./assets/fonts/OpenSans/OpenSans-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/OpenSans/OpenSans-Regular.woff2") format("woff2"),
    url("./assets/fonts/OpenSans/OpenSans-Regular.woff") format("woff"),
    url("./assets/fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans", arial;
  src: url("./assets/fonts/OpenSans/OpenSans-SemiBold.eot");
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url("./assets/fonts/OpenSans/OpenSans-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/OpenSans/OpenSans-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/OpenSans/OpenSans-SemiBold.woff") format("woff"),
    url("./assets/fonts/OpenSans/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans", arial;
  src: url("./assets/fonts/OpenSans/OpenSans-ExtraBold.eot");
  src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"),
    url("./assets/fonts/OpenSans/OpenSans-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/OpenSans/OpenSans-ExtraBold.woff2") format("woff2"),
    url("./assets/fonts/OpenSans/OpenSans-ExtraBold.woff") format("woff"),
    url("./assets/fonts/OpenSans/OpenSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans", arial;
  src: url("./assets/fonts/OpenSans/OpenSans-BoldItalic.eot");
  src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"),
    url("./assets/fonts/OpenSans/OpenSans-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/OpenSans/OpenSans-BoldItalic.woff2") format("woff2"),
    url("./assets/fonts/OpenSans/OpenSans-BoldItalic.woff") format("woff"),
    url("./assets/fonts/OpenSans/OpenSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

body {
  margin: 0;
  font-family: "Open Sans" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: rtl;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container ::-webkit-scrollbar {
  height: 0 !important;
}

/* Track */
.container ::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
.container ::-webkit-scrollbar-thumb {
  background: #888 !important;
}

/* Handle on hover */
.container ::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.isLink {
  cursor: pointer;
}

.arrow-rotate-top {
  transform: rotate(180deg);
}
